<template>
  <ViewFrequentlyAskedQuestions :frequentlyAskedQuestionID="parseInt(frequentlyAskedQuestionID)" />
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ViewFrequentlyAskedQuestions from '@/pages/Shared/frequentlyAskedQuestions/View'

import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'HomeownerFrequentlyAskedQuestions',

  components: {
    ViewFrequentlyAskedQuestions
  },

  data,
  methods,

  computed: {
    ...mapState({
      ...mapGetters('user', ['currentHoaId'])
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { frequentlyAskedQuestions: { title: 'Frequently Asked Questions' } }
    }
  }
}
</script>

<style></style>
