import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  showFilters: false,
  selectedFaq: null,
  toggle: false,
  togglePlus: 'icon-pluscircle',
  toggleIndex: 0,
  frequentlyAskedQuestions: [],
  frequentlyAskedQuestionID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'question',
      label: 'Question',
      aria: 'Question',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'answer',
      label: 'Answer',
      aria: 'Answer',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      width: '20%',
      date: true,
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
