import Button from '@/components/buttons/Button'
import _get from 'lodash/get'
import { frequentlyAskedQuestionStore } from '@/services/FrequentlyAskedQuestions/store'
import moment from 'moment'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const dateFormat = 'LL'
      const faqId = _get(entry, 'frequentlyAskedQuestionID', 0)
      const question = _get(entry, 'question', '')
      const answer = _get(entry, 'answer', '')
      let createdDate = _get(entry, ['createdDate'], '')
      let date = createdDate

      try {
        date = moment(createdDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        question,
        answer,
        createdDate: {
          date,
          format: dateFormat
        },
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processEdit(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDelete(question, faqId)
            },
            text: 'Delete'
          }
        }
      }
    })

    this.rows = rows
  },

  confirmDelete(question, id) {
    this.$buefy.dialog.confirm({
      title: 'Deleting FAQ',
      message: `Are you sure you want to <b>delete</b> this frequently asked question, '${question}'?`,
      confirmText: 'Delete Question',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteFaq(id)
    })
  },

  async deleteFaq(id) {
    console.debug('deleteFaq...')
    await frequentlyAskedQuestionStore.dispatch('deleteFrequentlyAskedQuestions', {
      frequentlyAskedQuestionID: id,
      done: async () => {
        this.reload()
      }
    })
  },

  processPlus(index) {
    this.toggleIndex = index
    if (this.togglePlus === 'icon-pluscircle') {
      this.togglePlus = 'icon-minuscircle'
    } else {
      this.togglePlus = 'icon-pluscircle'
    }
  },

  newFrequentlyAskedQuestion() {
    this.$router.push('/newFrequentlyAskedQuestion')
  },

  async loadFrequentlyAskedQuestions() {
    console.debug('in loadFrequentlyAskedQuestions()...' + this.currentHoaId || this.hoaId)

    await frequentlyAskedQuestionStore
      .dispatch('getFrequentlyAskedQuestionList', {
        hoaID: this.currentHoaId || this.hoaId
      })
      .then(({ list }) => {
        if (list) {
          this.determineRows(list)
        }
      })
  },

  addFrequentlyAskedQuestion() {
    console.debug('add addFrequentlyAskedQuestion...')
    this.selectedFaq = null
    this.toggle = true
  },

  processEdit(FAQ) {
    if (FAQ) {
      this.selectedFaq = FAQ
      this.toggle = true
    }
  },

  async reload() {
    this.loading = true

    this.rows = []
    await this.loadFrequentlyAskedQuestions()

    this.loading = false
  }
}
