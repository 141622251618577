<template>
  <PageContentLayoutOnly role="region">
    <div style="padding-bottom:10px;" v-for="(c, index) in rows" :key="index">
      <div v-if="c.question && c.answer">
        <div @click.prevent="processPlus(index)">
          <i :class="`${toggleIndex === index ? togglePlus : 'icon-pluscircle'}`" /><span
            class="pl-3 subtitle"
            style="color: black;"
            >{{ `${index + 1}. ` }}{{ c.question || '' }}</span
          >
        </div>
        <div
          class="pl-6 subtitle"
          v-if="togglePlus === 'icon-minuscircle' && toggleIndex === index"
        >
          {{ c.answer || '' }}
        </div>
      </div>
    </div>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'FrequentlyAskedQuestions',
  components: {
    PageContentLayoutOnly
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { frequentlyAskedQuestion: { title: 'Frequently Asked Questions' } }
    }
  }
}
</script>

<style></style>
